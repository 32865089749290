import { Link } from "gatsby"
import _ from "lodash"
import React, { useState } from "react"
import classnames from "classnames"
import ButtonCircle from "../../../../components/button-circle"
import "./subservices-styles.scss"
import { useTranslation } from "react-i18next"

const SubServices = ({ title, text, link, position, length }) => {
  const [onHover, setOnHover] = useState()

  const { i18n } = useTranslation();

  const handleClick = url => {
    // Save the current scroll position to localStorage
    localStorage.setItem("scrollPosition", window.scrollY.toString())
    // Navigate to the target URL
    window.location.href = url
  }
  // const getLastItems = () => {
  //   if (length % 2 === 0) {
  //     return length === position + 1 || position === (length - 2)
  //   }
  //   return length === position + 1
  // }
  const subservicesContainer = classnames({
    subservices__container: true,
    // border__card: getLastItems(),
  })

  const subservicesSubContainer = classnames({
    subservices__subcontainer: true,
    hover: onHover,
  })

  const handleMouseOver = () => {
    setOnHover(true)
  }

  const handleMouseOut = () => {
    setOnHover(false)
  }

  return (
    <div className={subservicesContainer} key={position}>
      <a
        href={`${i18n.language === 'en' ? '' : `/${i18n.language}`}/service/${link}/`}
        onClick={e => {
          e.preventDefault()
          handleClick(`${i18n.language === 'en' ? '' : `/${i18n.language}`}/service/${link}/`)
        }}
      >
        <div
          className={subservicesSubContainer}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
        >
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          <p>{text}</p>
          <div className="subservices__buttonContainer">
            <ButtonCircle transparent={onHover} />
          </div>
        </div>
      </a>
    </div>
  )
}

export default SubServices
