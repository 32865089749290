import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Seo,
  Spinner,
  BannerCTA,
  Tagline,
  Carousel,
  WaysHowCard,
} from "../../components"
import "./services-styles.scss"
import SubServices from "./components/sub-services"

const ServicesView = ({ data, loading }) => {
  // console.log("🚀 ~ file: services-view.js:16 ~ ServicesView ~ data", data)
  const { t } = useTranslation()

  useEffect(() => {
    // Retrieve the saved scroll position from localStorage
    const savedScrollPosition = localStorage.getItem("scrollPosition")

    if (savedScrollPosition) {
      // Wait a short time before scrolling
      setTimeout(() => {
        // Scroll to the saved position
        window.scrollTo(0, parseInt(savedScrollPosition))
        // Clear the saved scroll position
        localStorage.removeItem("scrollPosition")
      }, 100) // Adjust this delay as needed
    }
  }, [])

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <div className="services__container">
        <Seo title={_.get(data, "title")} />
        <div>
          {_.map(_.get(data, "acf.rows"), (layouts, idx) => {
            let layout
            switch (true) {
              // Services
              case layouts.acf_fc_layout === "hero_two_images_copy":
                layout = (
                  <div key={idx} className="services__servicesContainer">
                    <div className="services__servicesSubcontainer">
                      <Tagline text={t("tagline.services")} />
                      <div className="largeTitle">
                        <h1 className="page-title">{_.get(data, "title")}</h1>
                      </div>
                      <p className="servicesDescription">{layouts.copy}</p>
                      <div className="firstImage">
                        <img
                          src={layouts.image_second.url}
                          alt={layouts.image_second.alt}
                        />
                      </div>
                    </div>
                    <img
                      className="secondImage"
                      src={layouts.image.url}
                      alt={layouts.image.alt}
                    />
                  </div>
                )
                break

              // sub services
              case layouts.acf_fc_layout === "sub_services":
                layout = (
                  <div key={idx} className="services__subservicesContainer">
                    {layouts.list.map((subservice, i) => (
                      <SubServices
                        key={i}
                        position={i}
                        length={layouts.list.length}
                        title={subservice.title}
                        text={subservice.content}
                        link={subservice.slug}
                      />
                    ))}
                  </div>
                )
                break

              // how we do it
              case layouts.acf_fc_layout === "how_to_list":
                layout = (
                  <div key={idx} className="services__howToContainer">
                    <div className="carousel__tagline">
                      <h2>{t("services.how-we-do")}</h2>
                    </div>
                    <Carousel arrows={true} data={layouts.list}>
                      {layouts.list.map((work, i) => (
                        <div key={i}>
                          <WaysHowCard
                            title={work.title}
                            text={work.copy}
                            idx={i}
                            imgNumber="https://seasongr-1935.demosrv.dev/wp-content/uploads/2022/07/4.png"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )
                break

              default:
                break
            }
            return layout
          })}
        </div>
      </div>
      <BannerCTA />
    </React.Fragment>
  )
}

export default ServicesView
