import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import ServicesView from './services-view'
import { useTranslation } from 'react-i18next'

const ServicesController = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataServices, setDataServices] = useState([])
  const controller = new AbortController()

  const { i18n } = useTranslation();
  // catch with useEffect so the data will be contained
  const getDataServices = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf'
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'services',
      signal: controller.signal,
      params: {
        fields,
        slug,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataServices(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDataServices()
    let timeout
    if (typeof window !== 'undefined') {
      timeout = setTimeout(() => {
        window.scrollTo(0, 0)
      }, 400)
    }
    return () => {
      controller.abort()
      clearTimeout(timeout)
    }
  }, [slug])

  const viewProps = {
    data: dataServices,
    loading,
  }
  return (
    <ServicesView {...viewProps} />
  )
}

export default ServicesController
